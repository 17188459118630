import * as React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import CookieConsent from "react-cookie-consent";

type LayoutProps = {
  children: React.ReactNode;
};

const ComingSoon = false;

export default function Layout(props: LayoutProps) {
  return (
    <>
      {!ComingSoon ? (
        <>
          <main>
            <Navbar />
            {props.children}
          </main>
        </>
      ) : (
        <></>
      )}
      <CookieConsent
        location="bottom"
        buttonText="I UNDERSTAND &#x279C;"
        cookieName="strategix-cookie"
        style={{
          background: "var(--blue)",
          opacity: "0.9",
        }}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "var(--red)",
          fontSize: "16px",
          borderRadius: "10px",
          padding: "10px 20px",
        }}
        expires={150}
      >
        <p
          style={{
            fontSize: "16px",
            paddingInline: "20px",
          }}
        >
          {" "}
          This website uses cookies in order to offer you the most relevant
          information. By clicking "I UNDERSTAND" you agree to us using cookies
          for optimal performance. For full details, please view our{" "}
          <a
            href="/privacy-policy"
            style={{ color: "white", fontStyle: "italic" }}
          >
            Privacy Policy.
          </a>
        </p>
      </CookieConsent>
      <Footer />
    </>
  );
}
