import { Link } from "gatsby";
import React, { useState } from "react";

//Style Sheets
import "../styles/nav.scss";
import "../styles/index.scss";

//Media
import logo from "../images/strategix-logo.svg";
import hamburger from "../images/hamburger.png";
// import { img } from 'gatsby-plugin-image';

export default function Navbar() {
  const [showServices, setShowServices] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  const props = {
    menuClass: !mobileMenu ? "mobile-menu" : "mobile-menu open",
  };

  return (
    <>
      <nav>
        <div className="nav-logo">
          <Link to="/">
            <img
              src={logo}
              width={200}
              height={100}
              alt="strategix technology solutions logo"
              className="resp-image"
            />
          </Link>
        </div>
        <div className="nav-menu">
          <div className="nav-menu-inner">
            <img
              alt="hamburger menu"
              src={hamburger}
              className="hamburger-icon"
              onClick={() => setMobileMenu(true)}
              style={{
                cursor: "pointer",
              }}
            />
            <div className="desktop-menu" style={{ zIndex: "9999" }}>
              <Link to="/">Home</Link>
              <Link
                to="/about"
                onMouseEnter={() => setShowAbout(true)}
                onMouseLeave={() => setShowAbout(false)}
              >
                <div>
                  About Us <span style={{ fontSize: "15px" }}>▼</span>
                  {showAbout ? (
                    <div id="submenu">
                      <Link to="/about#strategix-group">
                        <div id="submenu-item">The Strategix Group</div>
                      </Link>
                      <Link to="/about#values">
                        <div id="submenu-item">Our Values</div>
                      </Link>
                      <Link to="/about#ethos">
                        <div id="submenu-item">Ethos</div>
                      </Link>
                      <Link to="/about#sustainability">
                        <div id="submenu-item">Sustainability</div>
                      </Link>
                      <Link to="/about#team">
                        <div id="submenu-item">Our Team</div>
                      </Link>
                      {/* <div id="submenu-item">
                      <Link to="#">Products</Link>
                    </div> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Link>
              <Link
                to="#"
                onMouseEnter={() => setShowServices(true)}
                onMouseLeave={() => setShowServices(false)}
              >
                <div>
                  Solutions and Services{" "}
                  <span style={{ fontSize: "15px" }}>▼</span>
                  {showServices ? (
                    <div id="megamenu">
                      <div className="mega-col">
                        <Link to="/professional-services">
                          <div id="submenu-item">Professional Services</div>
                        </Link>
                        <Link to="/professional-services#engagement-methodology">
                          <div id="submenu-item">Engagement Methodology</div>
                        </Link>
                        <Link to="/professional-services#vmware-professional-services-competencies">
                          <div id="submenu-item">VMware Competencies</div>
                        </Link>
                        <Link to="/professional-services#our-strategic-elements">
                          <div id="submenu-item">Strategic Elements</div>
                        </Link>
                      </div>
                      <div className="mega-col">
                        <Link to="/managed-services">
                          <div id="submenu-item">Managed Services</div>
                        </Link>
                        <Link to="/managed-services#on-prem-infrastructure">
                          <div id="submenu-item">On-premise Infrastructure</div>
                        </Link>
                        <Link to="/data-protection-as-a-service">
                          <div id="submenu-item">Data Protection</div>
                        </Link>
                        <Link to="/managed-services#cloud-services">
                          <div id="submenu-item">Cloud Services</div>
                        </Link>
                        <Link to="/managed-services#mail">
                          <div id="submenu-item">Collaboration and Mail</div>
                        </Link>
                        <Link to="/managed-services#digital-workspace">
                          <div id="submenu-item">Digital Workspace</div>
                        </Link>
                        <Link to="/managed-services#security-management">
                          <div id="submenu-item">Security Management</div>
                        </Link>
                      </div>
                      <div className="mega-col">
                        <Link to="/cloud-services">
                          <div id="submenu-item">Cloud Services</div>
                        </Link>
                        {/* <div id="submenu-item">
                          <Link to="/professional-services">
                            Our Range of Cloud Services
                          </Link>
                        </div> */}
                        <Link to="/cloud-services#private-and-hybrid-cloud">
                          <div id="submenu-item">Private and Hybrid Cloud</div>
                        </Link>
                        <Link to="/cloud-services#orchestration-and-automation">
                          <div id="submenu-item">
                            Orchestration and Automation
                          </div>
                        </Link>
                        <Link to="/cloud-services#digital-enterprise">
                          <div id="submenu-item">Digital Enterprise</div>
                        </Link>
                      </div>
                      {/* <div id="submenu-item">
                        <Link to="/professional-services">
                          Professional Services
                        </Link>
                      </div>
                      <div id="submenu-item">
                        <Link to="/managed-services">Managed Services</Link>
                      </div>
                      <div id="submenu-item">
                        <Link to="/cloud-services">Cloud Services</Link>
                      </div> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Link>
              <Link to="/resources">Resource Centre</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
        </div>
        <div className={props.menuClass}>
          <div className="mobile-menu-close">
            <span
              onClick={() => setMobileMenu(false)}
              style={{
                color: "var(--white)",
                padding: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </span>
          </div>
          <Link to="/">
            <h2>Home</h2>
          </Link>
          <Link to="/about">
            <h2>About Us</h2>
          </Link>
          <h2
            onClick={() => setShowServices(!showServices)}
            style={{
              cursor: "pointer",
            }}
          >
            Solutions & Services
          </h2>
          {showServices ? (
            <div className="service-mobile-menu">
              <Link to="/professional-services">
                <h3>▶ Professional Services</h3>
              </Link>
              <Link to="/managed-services">
                <h3>▶ Managed Services</h3>
              </Link>
              <Link to="/cloud-services">
                <h3>▶ Cloud Services</h3>
              </Link>
              {/* <Link to="#">
                <h3>▶ Products</h3>
              </Link> */}
            </div>
          ) : (
            <></>
          )}
          <Link to="/resources">
            <h2>Resource Centre</h2>
          </Link>
          <Link to="/contact">
            <h2>Contact Us</h2>
          </Link>
        </div>
      </nav>
    </>
  );
}
